<template>
  <v-main>
    <v-container>
      <v-card>
        <v-card-title>
          <h3>Manage HRs</h3>
        </v-card-title>
        <v-card-text>
          <h3 v-if="hrs.length == 0"> 0 HRs found</h3>
        <v-snackbar
        v-model="error.status"
        relative
        color="error"
        bottom
      >
        {{error.message}}
        <template v-slot:action="{ attrs }">
          <v-btn
          color="black"
            text-color="error"
            v-bind="attrs"
            @click="error.status = false"
          >
            Close
          </v-btn>
        </template>
      </v-snackbar>
      <v-snackbar
        v-model="success.status"
        relative
        color="success"
        bottom
      >
        {{success.message}}
        <template v-slot:action="{ attrs }">
          <v-btn
          color="black"
            text-color="error"
            v-bind="attrs"
            @click="error.status = false"
          >
            Close
          </v-btn>
        </template>
      </v-snackbar>
        </v-card-text>
        <v-list>
        <v-list-item-group v-model="listModel">
          <v-list-item
            v-for="(item, i) in hrs"
            :key="i"
          >
            <v-list-item-content>
              <div style="display:inline-flex; justify-content: space-evenly;" width="100%">
                <v-row>
                  <v-col md="5"><label> {{ item.name}} </label></v-col>
                  <v-col md="5"><label> {{ item.email}} </label></v-col>
                </v-row>
                <v-btn v-if="isInvite" color="success"  height="100%" @click="reInviteUser(item)" :loading= "item.reinviteLoading" >Re-Share</v-btn>
                <v-btn v-else color="red"  height="100%" @click="index=i,dialog=true;" :loading= "deleteLoading" >Delete</v-btn>
              </div>
              <v-divider></v-divider>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
      </v-card>
      <v-dialog
        v-model="dialog"
        persistent
        max-width="290"
      >
        <v-card>
          <v-card-title class="headline">
            Confirm Action
          </v-card-title>
          <v-card-text>You will permanently delete the applicant credentials and application information</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="green darken-1"
              @click="dialog = false"
            >
              Cancel
            </v-btn>
            <v-btn
              color="error"
              @click="dialog = false; deleteUser();"
            >
              Confirm
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </v-main>
</template>
<script>
const axios = require('axios');

export default {
  data() {
    return {
      dialog: false,
      deleteLoading: false,
      reinviteLoading: false,
      error: {
        status: false,
        message: '',
      },
      success: {
        status: false,
        message: '',
      },
      hrs: [],
      listModel: '',
      isInvite: true,
      mode: 'Invite Mode',
      index: 0,
    };
  },
  methods: {
    async reInviteUser(item) {
      this.reinviteLoading = true;
      item.reinviteLoading = true;
      const userToInvite = item;
      const dataToSend = {
        email: userToInvite.email,
        password: userToInvite.password,
        type: 'Admin',
      };
      const bufferObj = Buffer.from(JSON.stringify(dataToSend), 'utf8');
      // Encode the Buffer as a base64 string
      const encodedData = bufferObj.toString('base64');
      const responseAx = await axios({
        // praform-a3e92, manipal-pra-app
        url: 'https://us-central1-manipal-pra-app.cloudfunctions.net/sendMail',
        data: {
          payload: encodedData,
        },
        method: 'post',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Accept: 'application/json; charset=utf-8',
        },
      });
      if (responseAx.data === 'Send mail Sended') {
        this.success.message = 'Re-Share successful';
        this.success.status = true;
      } else {
        this.error.message = 'Re-Share unsuccessful';
        this.error.status = true;
      }
      this.reinviteLoading = false;
      item.reinviteLoading = false;
    },
    async deleteUser() {
      this.deleteLoading = true;
      const userToDelete = this.hrs[this.index];
      const dataToSend = {
        email: userToDelete.email,
      };
      const bufferObj = Buffer.from(JSON.stringify(dataToSend), 'utf8');
      // Encode the Buffer as a base64 string
      const encodedData = bufferObj.toString('base64');
      const responseAx = await axios({
        // praform-a3e92,
        url: 'https://us-central1-manipal-pra-app.cloudfunctions.net/deleteUser',
        data: {
          payload: encodedData,
        },
        method: 'post',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Accept: 'application/json; charset=utf-8',
        },
      });
      if (responseAx.data === 'Successfully deleted user') {
        try {
          await this.firebase.firestore().collection('usersList').doc(userToDelete.email).delete();
          await this.firebase.firestore().collection('actionLog').add({
            profileName: userToDelete.name,
            profileMail: userToDelete.email,
            delete: false,
            signature: `super-admin-${this.$store.state.user.currentUser.email}`,
          });
          this.hrs.splice(this.index, 1);
          this.success.message = 'Delete action successful';
          this.success.status = true;
        } catch (err) {
          // console.log(err);
          this.error.message = '<span class="alert-error">&#128533;</span>Issue in deletion. Please try again later.';
          this.error.status = true;
        }
      } else {
        // console.log(responseAx);
        this.error.message = 'Delete action unsuccessful';
        this.error.status = true;
      }
      this.deleteLoading = false;
      this.index = 0;
    },
    async getHrs() {
      // console.log('ime');
      // here we will get the user
      this.error.status = false;
      this.success.status = false;
      try {
        const snapshot = await this.firebase.firestore().collection('usersList').get();
        // console.log(snapshot);
        snapshot.forEach(async (item) => {
          // console.log(item);
          const tempItem = { name: item.name, password: item.password, ...await item.data() };
          if (tempItem.admin !== undefined) {
            if (tempItem.admin === true) {
              this.hrs.push(tempItem);
            }
          }
        });
      } catch (err) {
        // console.log(err);
        this.error.message = '<span class="alert-error">&#128533;</span>Something went wrong. Please try again in some time.';
        this.error.status = true;
      }
      // console.log(this.userCode);
    },
  },
  mounted() {
    this.getHrs();
  },
  watch: {
    isInvite() {
      this.hrs = [];
      this.getHrs();
      if (this.isInvite) {
        this.mode = 'Invite Mode';
      } else {
        this.mode = 'Delete Mode';
      }
    },
  },
};
</script>

<style>
.alert-success, .alert-error{
  font-size: 30px;
  vertical-align: middle;
  margin-right: .5em;
}
</style>
