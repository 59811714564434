<template>
  <v-main>
    <v-container>
      <v-card>
        <v-card-title>
          <h3>View Logs</h3>
        </v-card-title>
        <v-card-text>
          <h3 v-if="logs.length == 0"> 0 logs found</h3>
        </v-card-text>

        <v-snackbar
        v-model="error.status"
        relative
        color="error"
        bottom
      >
        {{error.message}}
        <template v-slot:action="{ attrs }">
          <v-btn
          color="black"
            text-color="error"
            v-bind="attrs"
            @click="error.status = false"
          >
            Close
          </v-btn>
        </template>
      </v-snackbar>
      <v-snackbar
        v-model="success.status"
        relative
        color="success"
        bottom
      >
        {{success.message}}
        <template v-slot:action="{ attrs }">
          <v-btn
          color="black"
            text-color="error"
            v-bind="attrs"
            @click="error.status = false"
          >
            Close
          </v-btn>
        </template>
      </v-snackbar>
        <v-list>
        <v-list-item-group v-model="listModel">
          <v-list-item
            v-for="(item, i) in logs"
            :key="i"
          >
            <v-list-item-content>
              <div style="display:inline-flex; justify-content: space-evenly;" width="100%">
                <v-row>
                  <v-col md="2"><label> {{ item.profileName }} </label></v-col>
                  <v-col md="3"><label> {{ item.profileMail }} </label></v-col>
                  <v-col md="3"><label> {{ item.timeStamp }} </label></v-col>
                  <v-col md="3" v-if="item.converted"><label style="color: orange;"> converted to Post by {{item.signature}} </label></v-col>
                  <v-col md="3" v-else ><label style="color: green;"> created by {{item.signature}} </label></v-col>
                </v-row>
              </div>
              <v-divider></v-divider>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
      </v-card>
</v-container>
  </v-main>
</template>
<script>
export default {
  data() {
    return {
      logs: [],
      listModel: '',
      error: {
        status: false,
        message: '',
      },
      success: {
        status: false,
        message: '',
      },
    };
  },
  methods: {
    async getLogs() {
      // console.log('ime');
      // here we will get the user
      try {
        const snapshot = await this.firebase.firestore().collection('actionLog').get();
        // console.log(snapshot);
        snapshot.forEach(async (item) => {
          // console.log(item);
          const tempItem = { profileName: item.profileName, profileMail: item.profileMail, ...await item.data() };
          this.logs.push(tempItem);
        });
      } catch (err) {
        // console.log(err);
        this.error.message = '<span class="alert-error">&#128533;</span>Something went wrong. Please try again in some time.';
        this.error.status = true;
      }
      // console.log(this.userCode);
    },
  },
  mounted() {
    this.getLogs();
  },
};
</script>
