<template>
  <v-main>
    <v-container>
      <v-card>
        <v-card-title>
          <h3>Applicants Submission List</h3>
        </v-card-title>
        <label v-if="applicants.length == 0"> 0 applicant forms found</label>
        <v-list>
        <v-list-item-group v-model="listModel">
          <v-list-item
            v-for="(item, i) in applicants"
            :key="i"
          >
            <v-list-item-content>
              <div style="display: flex;flex-flow: row wrap;justify-content: space-around" width="100%">
               <v-row>
                  <v-col md="5"><label> {{ item.name}} </label></v-col>
                  <v-col md="5"><label> {{ item.email}} </label></v-col>
                  <v-col md="2" v-if="!item.postRecruitment"><v-btn color="orange"  height="100%" @click="dialog=true;currentItem=item;"
                  :loading= "item.convertLoading" >Convert</v-btn></v-col>
                  <v-col md="2" v-else><label> Post-Rec. </label></v-col>
                </v-row>
              <v-btn color="success"  height="100%" @click="goToSelectedForm(i)">View Application</v-btn>
              </div>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
      </v-card>
      <v-snackbar
        v-model="error.status"
        relative
        color="error"
        bottom
      >
        {{error.message}}
        <template v-slot:action="{ attrs }">
          <v-btn
          color="black"
            text-color="error"
            v-bind="attrs"
            @click="error.status = false"
          >
            Close
          </v-btn>
        </template>
      </v-snackbar>
      <v-snackbar
        v-model="success.status"
        relative
        color="success"
        bottom
      >
        {{success.message}}
        <template v-slot:action="{ attrs }">
          <v-btn
          color="black"
            text-color="error"
            v-bind="attrs"
            @click="error.status = false"
          >
            Close
          </v-btn>
        </template>
      </v-snackbar>
       <v-dialog
        v-model="dialog"
        persistent
        max-width="360"
      >
        <v-card>
          <v-card-title class="headline">
            Confirm Action
          </v-card-title>
          <v-card-text>You will permanently convert the applicant's form to and post-recruitment mode</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="orange"
              @click="dialog = false"
            >
              Cancel
            </v-btn>
            <v-btn
              color="success"
              @click="dialog = false; convertUser();"
            >
              Confirm
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </v-main>
</template>

<script>
import moment from 'moment';

export default {
  data() {
    return {
      dialog: false,
      loading: false,
      error: {
        status: false,
        message: '',
      },
      success: {
        status: false,
        message: '',
      },
      applicants: [],
      listModel: '',
      convertLoading: false,
      currentItem: null,
    };
  },
  methods: {
    async addLog() {
      await this.firebase.firestore().collection('actionLog').add({
        profileName: this.currentItem.name,
        profileMail: this.currentItem.email,
        timeStamp: moment(this.theDate).format('DD/MMM/YYYY HH:mm:ss'),
        delete: false,
        converted: true,
        signature: this.$store.state.user.currentUser.email,
      });
      // console.log('12323');
    },
    async convertUser() {
      this.currentItem.convertLoading = true;
      const userToConvert = this.currentItem;
      try {
        await this.firebase.firestore().collection('usersList').doc(userToConvert.email).update({
          postRecruitment: true,
        });
        await this.firebase.firestore().collection('allSectionsIDmap').doc(userToConvert.email).update({
          postRecruitment: true,
          updatedStep: 1,
        });
        this.addLog();
      } catch (err) {
        // console.log(err);
        this.error.message = '<span class="alert-error">&#128533;</span>Something went wrong. Please try again in some time.';
        this.error.status = true;
      }
      this.currentItem.convertLoading = false;
      this.getUser();
      this.success.status = true;
      this.success.message = 'Candidate conversion successful';
    },
    goToSelectedForm(index) {
      // console.log(this.applicants[index].keyMapDict);
      this.$router.push({ name: 'display-form', params: { email: this.applicants[index].email, name: this.applicants[index].name } });
    },
    async getUser() {
      this.applicants = [];
      // here we will get the user
      this.error.status = false;
      this.success.status = false;
      try {
        const snapshot = await this.firebase.firestore().collection('allSectionsIDmap').get();
        // console.log(snapshot);
        snapshot.forEach(async (item) => {
          // console.log(item);
          const tempItem = { name: item.name, email: item.email, ...await item.data() };
          if (tempItem.referenceDetails !== undefined) {
            if (tempItem.updatedStep === 7) {
              this.applicants.push(tempItem);
            }
          }
        });
      } catch (err) {
        // console.log(err);
        this.error.message = '<span class="alert-error">&#128533;</span>Something went wrong. Please try again in some time.';
        this.error.status = true;
      }
      // console.log(this.userCode);
    },
    resetForm() {
      this.email = null;
      this.code = null;
      this.name = null;
      this.phone = null;
      this.type = null;
    },
  },
  computed: {
    emailRules() {
      return [
        (v) => !!v || `${this.emailLabel} is required`,
        (v) => v === null || (v.length === 0) || /.+@.+\..+/.test(v) || `${this.phoneLabel} must be valid`,
      ];
    },
    phoneRules() {
      return [
        (v) => !!v || `${this.phoneLabel} is required`,
        (v) => v === null || (v.length === 10) || `${this.phoneLabel} must be 10 characters`,
      ];
    },
  },
  mounted() {
    this.getUser();
  },
};
</script>

<style>
.alert-success, .alert-error{
  font-size: 30px;
  vertical-align: middle;
  margin-right: .5em;
}
</style>
